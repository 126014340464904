import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
import CommonHero from '../components/commonhero/commonhero.js'

class TestimonialPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Testimonials' }}>
        <CommonHero
          pageTitle="Testimonials"
          custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1556763394/brianboals/citybg.jpg"
        />
        <div className="py-5 bg-dirty-white">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-5">
                <div className="row mt-5">
                  <div className="col-md-6 mb-5">
                    <div className="tes-vid">
                      <iframe
                        title="video"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/srtw-m2GGZ0"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ marginRight: `10px` }}
                      />
                      <div className="video-title">
                        <h3>
                          Base on your experience with selling your house and
                          purchasing a new home, what would tell someone else
                          about working with The Brian Boals Team?
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="tes-vid">
                      <iframe
                        title="video"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/Kb5uRn0d5yY"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ marginRight: `10px` }}
                      />
                      <div className="video-title">
                        <h3>
                          What was your favorite part about working with The
                          Brian Boals Team?
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="tes-vid">
                      <iframe
                        title="video"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/Wuzl4tMfSJg"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ marginRight: `10px` }}
                      />
                      <div className="video-title">
                        <h3>
                          How did The Brian Boals Team put your mind at ease
                          during the whole selling and buying process?
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="tes-vid">
                      <iframe
                        title="video"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/1Ch_YMe6OCE"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ marginRight: `10px` }}
                      />
                      <div className="video-title">
                        <h3>
                          What did you find surprising and helpful about working
                          with The Brian Boals Team instead of another realtor?
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="tes-vid">
                      <iframe
                        title="video"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/9oq5SFL3WW8"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ marginRight: `10px` }}
                      />
                      <div className="video-title">
                        <h3>
                          Why did you decide to work with The Brian Boals Team
                          over another realtor?
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-5">
                    <div className="tes-vid">
                      <iframe
                        title="video"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/leTbDXsdbOI"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ marginRight: `10px` }}
                      />
                      {/* <div className="video-title">
                        <h3>
                          Why did you decide to work with The Brian Boals Team
                          over another realtor?
                        </h3>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-md-6 mb-5 mx-auto">
                    <div className="tes-vid">
                      <iframe
                        title="video"
                        width="100%"
                        height="315"
                        src="https://www.youtube.com/embed/wtpvf6yJcDY"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{ marginRight: `10px` }}
                      />
                      {/* <div className="video-title">
                        <h3>
                          Why did you decide to work with The Brian Boals Team
                          over another realtor?
                        </h3>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <iframe
                  title="video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Wuzl4tMfSJg"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  style={{ marginRight: `10px` }}
                />
                <iframe
                  title="video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/1Ch_YMe6OCE"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  style={{ marginRight: `10px` }}
                />
                <iframe
                  title="video"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/9oq5SFL3WW8"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  style={{ marginRight: `10px` }}
                /> */}
              </div>
              {this.props.data.allSanityTestimonial.edges.map(testimonial => (
                <div
                  className="col-md-6 mx-auto col-sm-10 mb-4"
                  key={testimonial.node.id}
                >
                  <div className="vendor text-center card">
                    <h4>{testimonial.node.name}</h4>

                    <p>{testimonial.node.description}</p>
                    <div className={`rating-${testimonial.node.rating}`}>
                      <i className="fa fa-star" aria-hidden="true" />
                      <i className="fa fa-star" aria-hidden="true" />
                      <i className="fa fa-star" aria-hidden="true" />
                      <i className="fa fa-star" aria-hidden="true" />
                      <i className="fa fa-star" aria-hidden="true" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default TestimonialPage

export const query = graphql`
  query testimonialQuery {
    allSanityTestimonial(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          name
          rating
          description
          publishedAt
        }
      }
    }
  }
`
